:root {

    --primary: rgb(var(--rgb-primary));
    --rgb-primary: 18, 18, 18;
    --white: rgb(var(--rgb-white));
    --rgb-white: 255, 255, 255;
    --grey-1: rgb(var(--rgb-grey-1));
    --rgb-grey-1: 221, 221, 221;
    --grey-2: rgb(var(--rgb-grey-2));
    --rgb-grey-2: 238, 238, 238;
    --grey-3: rgb(var(--rgb-grey-3));
    --rgb-grey-3: 0, 0, 98;


    --color1: #2C4F4B;
    --color2: #353535;
    --color3: #DAD0C3;
    --colorb: #111;
    --colorw: #fff;
    --colorw2: var(--color3);


    --section_padd: calc(35px + 40 * (100vw - 320px) / 1080);
    --container_padding: clamp(2.5em, 8vw, 8em);
    --gap_padding: clamp(1.5em, 4vw, 2.5em);
    --gutter: calc(15px + 10 * (100vw - 320px) / 1080);
    --gutter-min: calc(10px + 10 * (100vw - 320px) / 1080);
    --titlemarg: calc(25px + 10 * (100vw - 320px) / 1080) 0;

    /* header */

    --header-height: calc(var(--header-top) + var(--header-bottom));
    --header-top: clamp(20px, 5vh, 30px);
    --header-bottom: clamp(70px, 15vh, 120px);

    /* transitions */

    --transition_open: .5s cubic-bezier(0, .97, 0, .97);

    /* shadows */

    --shadow-section: 1px 1px 12px -3px rgba(0, 0, 0, .05);

    /* all */

    --height_toolbar: 65px;
}

/* font-face  */


@font-face {
    font-display: auto;
    font-family: 'Cormorant';
    src: local('Cormorant Regular'), local('Cormorant-Regular'), url('../../fonts/cormorant/Cormorant-Regular.woff2') format('woff2');
}

@font-face {
    font-display: auto;
    font-family: 'Cormorant';
    src: local('Cormorant Bold'), local('Cormorant-Bold'), url('../../fonts/cormorant/Cormorant-Bold.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-display: auto;
    font-family: 'Cormorant';
    src: local('Cormorant Italic'), local('Cormorant-Italic'), url('../../fonts/cormorant/Cormorant-Italic.woff2') format('woff2');
    font-style: italic;
}


@font-face {
    font-display: auto;
    font-family: 'Cormorant';
    src: local('Cormorant Medium'), local('Cormorant-Medium'), url('../../fonts/cormorant/Cormorant-Medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-display: auto;
    font-family: 'Cormorant';
    src: local('Cormorant SemiBold'), local('Cormorant-SemiBold'), url('../../fonts/cormorant/Cormorant-SemiBold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-display: auto;
    font-family: 'OpenSans';
    src: local('OpenSans Regular'), local('OpenSans-Regular'), url('../../fonts/opensans/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
    font-display: auto;
    font-family: 'OpenSans';
    src: local('OpenSans Bold'), local('OpenSans-Bold'), url('../../fonts/opensans/OpenSans-Bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-display: auto;
    font-family: 'OpenSans';
    src: local('OpenSans ExtraBold'), local('OpenSans-ExtraBold'), url('../../fonts/opensans/OpenSans-ExtraBold.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-display: auto;
    font-family: 'OpenSans';
    src: local('OpenSans Italic'), local('OpenSans-Italic'), url('../../fonts/opensans/OpenSans-Italic.woff2') format('woff2');
    font-style: italic;
}
