@import "mods/reset.css";
@import "mods/var.css";
@import "mods/components.css";

/* /////////////////// GENERAL ///////////////// */

html, body {
    line-height: 1;
    background: var(--color3);
    width: 100%;
    color: var(--color1);
    overflow-x: hidden;
    font-display: auto;
    font-family: 'OpenSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    overflow: hidden;
}

p {
    color: var(--color1);
}

.ct {
    margin: 0 15%;
    max-width: 1344px;
}

p, li {
    line-height: 1.6;
}

.gold {
    color: var(--color2);
}

.bold {
    font-weight: 600;
}


/* /////////////////// HEADER ///////////////// */


.header-top {
    background-color: var(--color1);
    color: var(--colorw);
    height: var(--header-top);
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-bottom nav {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: var(--color3);
    transition: transform .6s;
}

.logo {
    transition: transform .6s;
    transform: translateY(36px);
}

.logo img{
    max-height: 9rem;
    max-width: 100%;
}

.fix .logo {
    transform: translateY(0) scale(0.55);
}

.fix .header-bottom nav {
    transform: translateY(clamp(-30px, -5vh, -30px));
}

.header-bottom nav .wrapper {
    position: relative;
    padding: 0 5%;
    height: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fix .header-bottom nav .wrapper {
    box-shadow: 1px 1px 5px rgb(50, 51, 82, 0.2);
}

.header-bottom .wrapper .nav-links {
    display: inline-flex;
    align-items: center;
}

.header-bottom .nav-links li {
    list-style: none;
}

.header-bottom .nav-links li a {
    color: var(--color1);
    text-decoration: none;
    font-weight: 600;
    font-size: calc(11px + 2 * (100vw - 320px) / 1080);
    padding: 9px 15px;
    text-align: center;
    transition: all 450ms ease;
}

.header-bottom .nav-links li a:hover:not(a.bn.purple) {
    background: var(--color1);
    border-radius: 10px;
    color: var(--colorw);
}

.header-bottom .nav-links .mobile-item {
    display: none;
}

.header-bottom .nav-links .drop-menu {
    position: absolute;
    background: var(--color3);
    width: 200px;
    line-height: 45px;
    top: 30px;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    transition: all 0.6s ease;
}

.header-bottom .nav-links li:hover .drop-menu {
    top: 55px;
    opacity: 1;
    visibility: visible;
}

.header-bottom .drop-menu li a {
    width: 100%;
    display: block;
    padding: 15px 0 15px 15px;
    font-weight: 400;
    border-radius: 0;
    text-align: left;
}

.header-bottom .wrapper .btn {
    cursor: pointer;
    display: none;
}

.header-bottom .wrapper .btn.close-btn {
    position: absolute;
    right: 30px;
    top: 10px;
}

.header-bottom .wrapper .btn.close-btn svg {
    stroke: var(--color1);
    fill: var(--color1);
    width: 80px;
}

.header-bottom nav input {
    display: none;
}

/* *** Boutons *** */

.bn.white {
    background-color: var(--colorw);
}

.bn.purple {
    background-color: var(--color1);
    color: var(--colorw);
}

.bn.border {
    background: transparent;
    border: 2px solid var(--color1);
}

.bn.border-white {
    background: transparent;
    border: 2px solid var(--colorw);
    float: right;
    padding: 15px;
}

.bn.border-white svg {
    stroke: var(--colorw);
    width: 30px;
    stroke-width: 2px;
}

.bn {
    border-radius: 10px;
    font-size: 1rem;
    z-index: 1;
    display: block;
    text-align: center;
    padding: 20px 25px;
    width: fit-content;
}

.bn:not(nav .bn, #footer .bn, .reas-filter .bn) {
    margin: var(--titlemarg);
}

.bn:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.bn.purple:hover {
    background-color: var(--colorw);
    border: 1px solid var(--color1);
}

.bn.border-white:hover {
    background-color: var(--colorw);
    border: 1px solid var(--color1);
}

.bn.border-white:hover svg {
    stroke: var(--color1);
}

.bn.purple:hover span {
    color: var(--color1);
}

.bn.white:hover span, .bn.border:hover span {
    color: var(--colorw);
}

.bn.white:hover, .bn.border:hover {
    background-color: var(--color1);
    color: var(--colorw);
}

.bn:hover:after {
    left: 0;
    width: 100%;
}

.bn:active {
    top: 2px;
}

.bn span {
    font-weight: bold;
    white-space: nowrap;
}

.bn.purple span {
    color: var(--colorw);
}

.bn.white span, .bn.border span {
    color: var(--color1);
}

/* *** Titres *** */


h1, h2, h3, h4, h5 {
    font-display: auto;
    font-family: 'Cormorant', Helvetica, serif;
}

.title-center {
    text-align: center;
}

.title-left {
    text-align: left;
}

.title-right {
    text-align: right;
}

.title-right + p {
    text-align: right;
}

.title-center, .title-left, .title-right {
    margin: var(--titlemarg);
    text-transform: uppercase;
}

.title-center h2, .title-left h2, .title-right h2 {
    color: var(--color2);
    font-size: calc(12px + 2 * (100vw - 320px) / 1080);
    font-weight: 600;
    font-display: auto;
    font-family: 'OpenSans', Helvetica, sans-serif;
    letter-spacing: 3px;
    padding-bottom: 5px;
    white-space: nowrap;
    margin: 0 5px;
}

.title-center .title-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-center .ct {
    display: inline-block;
}

.title-center h3, .title-left h3, .title-right h3 {
    font-size: calc(25px + 5 * (100vw - 320px) / 1080);
    font-weight: 900;
    letter-spacing: 3px;
}

.title-center .left-stroke-center, .title-center .right-stroke-center {
    background-color: var(--color2);
    height: 2px;
    width: 100%;
    top: -2px;
}

.left-stroke-left, .right-stroke-right {
    background-color: var(--color2);
    height: 2px;
    width: 140px;
    top: 5px;
    position: absolute;
    z-index: 999;
}

.right-stroke-right {
    right: -150px;
}

.left-stroke-left {
    left: -150px;
}


/* *** MAGASINS *** */

.mag-coord {
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(50, 51, 82, 0.8);
    text-align: center;
    color: #fff;
    transition: all 450ms ease;
    display: flex;

    border-radius: 10px;
}

.mag-coord {
    flex-direction: column;
    justify-content: center;
}

.mag-coord > * {
    padding: 10px 0;
    font-weight: 600;
}

#rea-index #flex-reaindex .des-reaindex {
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.mag-coord > p {
    color: var(--colorw);
}

.mag-coord:hover, #rea-index #flex-reaindex .des-reaindex:hover {
    opacity: 1;
}

.mag-coord .tel-mag {
    display: block;
    color: var(--color2);
}

#mag-index .container {
    margin: var(--titlemarg);
}

#mag-index .swiper-slide img {
    border-radius: 10px;
    width: 100%;
}

#mag-index .swiper-slide .title-mag h4 {
    font-size: calc(14px + 2 * (100vw - 320px) / 1080);
    font-display: auto;
    font-family: 'OpenSans', Helvetica, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
    margin: 0 10px;
}

#mag-index [data-swiper="magasins"] .sw-n .bn.next-magasins {
    background-color: #fff;
}

#mag-index [data-swiper="magasins"] .sw-n .bn.prev-magasins {
    background-color: #E3E2E2;
}

#mag-index .swiper-slide .title-mag hr {
    background-color: var(--color1);
    height: 2px;
    width: 100%;
}

#mag-index .swiper-slide .title-mag {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

/* ///////////////// FOOTER ////////////// */

#footer {
    margin-top: var(--section_padd);
    background-color: var(--color1);
    color: var(--white);
    padding: var(--section_padd);
}

#footer .socials {
    display: flex;
    align-items: center;
}

#footer .socials li {
    background-color: var(--color2);
    border-radius: 5px;
    margin: 2px;
    font-weight: 600;
}

#footer .socials svg {
    width: 30px;
    fill: #fff;
}

#footer > .ct {
    display: flex;
    justify-content: space-between;
}

#footer > .ct > div {
    width: calc(100% / 5);
}

#footer > .ct > div:last-child {
    width: fit-content;
}

#footer > .ct ul li:not(.socials li) {
    padding: 10px 0;
}


/************* max 1150 **************/
@media screen and (max-width: 1250px) {

    .left-stroke-left, .right-stroke-right {
        display: none;
    }

    .title-center h2, .title-left h2, .title-right h2 {
        white-space: inherit;
        margin: 0;
    }

    #footer > .ct {
        margin: 0 5%;
        max-width: 1920px;
    }

    .header-bottom .wrapper .btn {
        display: block;
    }

    .header-bottom .wrapper .btn svg {
        fill: var(--color1);
        width: 45px;
    }

    .header-bottom .wrapper .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        margin-top: var(--header-top);
        top: -30px;
        left: -100%;
        background: var(--color3);
        display: block;
        padding: 50px 10px;
        line-height: 50px;
        overflow-y: auto;
        transition: all 1s ease;
    }

    .header-bottom #menu-btn:checked ~ .nav-links {
        left: 0;
    }

    .header-bottom #menu-btn:checked ~ .btn.menu-btn {
        display: none;
    }

    .header-bottom #close-btn:checked ~ .btn.menu-btn {
        display: block;
    }

    .header-bottom .nav-links li {
        margin: 15px 10px;
    }

    .logo {
        transform: none;
    }

    .header-bottom .nav-links li a {
        padding: 15px;
        display: block;
        font-size: calc(18px + 2 * (100vw - 320px) / 1080);
    }

    .header-bottom .nav-links .drop-menu {
        position: static;
        opacity: 1;
        top: 0px;
        visibility: visible;
        padding-left: 20px;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        box-shadow: none;
        transition: all 1s ease;
    }

    .header-bottom .bn.purple {
        padding-left: 15px;
    }

    #showDrop:checked ~ .drop-menu,
    #showDrop2:checked ~ .drop-menu {
        max-height: 100%;
        background-color: #fff;
    }

    .header-bottom .nav-links .desktop-item {
        display: none;
    }

    .header-bottom .nav-links .mobile-item {
        display: block;
        color: var(--color1);
        font-size: calc(18px + 2 * (100vw - 320px) / 1080);
        font-weight: 600;
        padding: 15px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .header-bottom .nav-links .mobile-item:hover {
        background: var(--color1);
        color: var(--colorw);
    }

    .header-bottom .drop-menu li {
        margin: 0;
    }

    .header-bottom .drop-menu li a {
        font-size: calc(18px + 2 * (100vw - 320px) / 1080);
    }

    .header-bottom .nav-links li a {
        text-align: left;
    }


}

/************* max 950 **************/
@media screen and (max-width: 950px) {

    #footer > .ct {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #footer > .ct > div {
        width: 100%;
    }

    #footer .socials {
        justify-content: center;
        margin-bottom: 30px;
    }

    .bn.border-white {
        margin-top: 30px;
    }

    .header-logo {
        top: 0;
    }

    .header-logo svg {
        width: 50%;
    }

}

/************* max 850 **************/
@media screen and (max-width: 850px) {

    .title-center .left-stroke-center, .title-center .right-stroke-center {
        display: none;
    }

    .title-center .title-up {
        display: inherit;
    }

    .title-center .ct {
        display: inherit;
        max-width: 1920px;
        margin: 0;
    }

    .title-center {
        text-align: left;
    }

    .bn {
        padding: 20px 15px;
    }

    .bn span {
        font-size: calc(14px + 2 * (100vw - 320px) / 1080);
    }

}

/************* max 850 **************/
@media screen and (max-width: 450px) {

    .mag-coord {
        opacity: 1;
        padding: 10px;
        font-size: calc(14px + 2 * (100vw - 320px) / 1080);
    }
}